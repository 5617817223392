<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>顾客之声</el-breadcrumb-item>
                <el-breadcrumb-item>贴心专业诊断</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class="">
                <!-- <button @click="openOne()">添加一级</button> -->
                <VyAddBtn @click.native="openOne()"></VyAddBtn>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border style="width: 100%" row-key="id" @row-click="handleRowClick" ref="tab">
                <el-table-column prop="title" align="center" label="分类名"> </el-table-column>
                <el-table-column prop="english" align="center" label="英文名"> </el-table-column>
                <el-table-column prop="image" align="center" label="图片">
                    <template slot-scope="{ row }">
                        <img :src="row.image" alt="" style="width: 100%" />
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="btns">
                            <div v-if="row.children">
                                <!-- <button
                                    @click="row.level == 1 ? openTwo(row) : row.level == 2 ? openThree(row) : ''"
                                    
                                >
                                    添加子分类
                                </button> -->
                                <VyAddBtn v-if="row.level == 1" @click.native="openTwo(row)" btnType="二级" />
                                <VyAddBtn v-if="row.level == 2" @click.native="openThree(row)" btnType="三级" />
                            </div>
                            <div class="_operate">
                                <button
                                    @click="
                                        row.level == 1
                                            ? openOne(row)
                                            : row.level == 2
                                            ? openTwo(row)
                                            : row.level == 3
                                            ? openThree(row)
                                            : ''
                                    "
                                >
                                    编辑
                                </button>
                            </div>
                            <div class="_delete">
                                <button @click="del(row)">删除</button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 添加或编辑一级分类弹框 -->
        <el-dialog
            :title="`${form_one.id ? '编辑' : '添加'}一级分类`"
            :visible.sync="dialogShow_one"
            width="40%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form_one" label-width="80px">
                <el-form-item label="分类名">
                    <el-input v-model.trim="form_one.title"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow_one = false">取 消</el-button>
                <el-button type="primary" @click="yes_one">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 添加或编辑二级分类弹框 -->
        <el-dialog
            :title="`${form_two.id ? '编辑' : '添加'}二级分类`"
            :visible.sync="dialogShow_two"
            width="40%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form_two" label-width="80px">
                <el-form-item label="分类名">
                    <el-input v-model.trim="form_two.title"></el-input>
                </el-form-item>
                <el-form-item label="英文名">
                    <el-input v-model.trim="form_two.english"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <upImg :src="form_two.image" @removeSrc="form_two.image = ''" ref="image"></upImg>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow_two = false">取 消</el-button>
                <el-button type="primary" @click="yes_two">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 添加或编辑三级分类弹框 -->
        <el-dialog
            :title="`${form_three.id ? '编辑' : '添加'}三级分类`"
            :visible.sync="dialogShow_three"
            width="40%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form_three" label-width="80px">
                <el-form-item label="分类名">
                    <el-input v-model.trim="form_three.title"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow_three = false">取 消</el-button>
                <el-button type="primary" @click="yes_three">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
import upImg from "../../customs/uploadImg.vue";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";
export default {
    components: { upImg, VyAddBtn },
    data() {
        return {
            tableData: [],
            dialogShow_one: false,
            form_one: {},
            dialogShow_two: false,
            form_two: {},
            dialogShow_three: false,
            form_three: {},
        };
    },
    async created() {
        this.tableData = await this.getData();
    },

    methods: {
        async getData() {
            let { data } = await axios.get("/api/mail/wcshow");
            data.forEach(e => {
                e.level = 1;
                if (e.children) {
                    e.children.forEach(el => {
                        el.level = 2;
                        if (el.children) {
                            el.children.forEach(ele => {
                                ele.level = 3;
                            });
                        }
                    });
                }
            });
            return data;
        },
        openOne(row) {
            if (row) {
                // 编辑一级
                this.form_one = {
                    id: row.id,
                    title: row.title,
                };
            } else {
                // 添加一级
                this.form_one = {
                    title: "",
                };
            }
            this.dialogShow_one = true;
        },
        async yes_one() {
            let { form_one } = this;
            if (form_one.id) {
                // 编辑一级
                var { data } = await axios.put("/api/mail/fupdate", QueryString.stringify(form_one));
            } else {
                // 添加一级
                var { data } = await axios.post("/api/mail/fadd", QueryString.stringify(form_one));
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.dialogShow_one = false;
            this.tableData = await this.getData();
        },
        openTwo(row) {
            if (row.level == 1) {
                // 添加二级
                this.form_two = {
                    title: "",
                    pid: row.id,
                    image: "",
                    english: "",
                };
                this.dialogShow_two = true;
            } else {
                // 编辑二级
                this.form_two = {
                    title: row.title,
                    pid: row.pid,
                    id: row.id,
                    image: row.image,
                    english: row.english,
                };
            }
            this.dialogShow_two = true;
        },
        async yes_two() {
            this.form_two.image = this.$refs.image.url;
            let { form_two } = this;
            if (form_two.id) {
                // 编辑二级
                var { data } = await axios.put(
                    "/api/mail/supdate",
                    QueryString.stringify({
                        ...form_two,
                    })
                );
            } else {
                // 添加二级
                var { data } = await axios.post(
                    "/api/mail/sadd",
                    QueryString.stringify({
                        ...form_two,
                    })
                );
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.dialogShow_two = false;
            this.tableData = await this.getData();
        },
        openThree(row) {
            if (row.level == 2) {
                // 添加三级
                this.form_three = {
                    title: "",
                    pid: row.id,
                };
            } else {
                // 编辑三级
                this.form_three = {
                    title: row.title,
                    pid: row.pid,
                    id: row.id,
                };
            }
            this.dialogShow_three = true;
        },
        async yes_three() {
            let { form_three } = this;
            if (form_three.id) {
                // 编辑三级
                var { data } = await axios.put("/api/mail/supdate", QueryString.stringify(form_three));
            } else {
                // 添加三级
                var { data } = await axios.post("/api/mail/sadd", QueryString.stringify(form_three));
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.dialogShow_three = false;
            this.tableData = await this.getData();
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;

            let { data } = await axios.delete("/api/mail/fdel", { params: { id } });
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
        },
        // 点击行
        handleRowClick(row, column, event) {
            if (event.target.tagName === "BUTTON") return;
            this.$refs["tab"].toggleRowExpansion(row);
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
</style>
